import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Formatter } from 'recharts/types/component/DefaultTooltipContent'
import { StatisticsHistogram } from '../../../../services/api/statistics/StatisticsService.types'
import { formatCurrency } from '../../../../utils/Helper'
import { useTranslation } from 'next-i18next'
import CardInfo from '../CardInfo'
import React, { useCallback } from 'react'
import useTheme from '../../../../hooks/useTheme'
import useUserCurrency from '../../../../hooks/useUserCurrency'

export type TurnoverChartCardInfoProps = {
    histogram: StatisticsHistogram[]
}

const getNameOfMonth = (value: string) => {
    const splitedValue = value.split('-')
    const year = splitedValue[1]
    const month = splitedValue[0]
    const date = new Date(`${year}-${month}-01`)
    const nameOfMonth = date.toLocaleDateString('cs-CZ', {
        month: 'long'
    })

    return nameOfMonth.charAt(0).toUpperCase() + nameOfMonth.slice(1)
}

const TurnoverChartCardInfo = ({ histogram }: TurnoverChartCardInfoProps) => {
    const userLanguage = useUserCurrency()
    const { primaryColor } = useTheme()
    const { t } = useTranslation()

    const currencyFormatter = useCallback<Formatter<string, string>>(
        (value, name, payload) => {
            if (payload.dataKey === 'revenue') {
                return formatCurrency(value, userLanguage)
            }
            return value
        },
        [userLanguage]
    )

    const monthFormatter = useCallback((value: string) => {
        return value.replace('-', '.')
    }, [])

    return (
        <CardInfo title='' className='h-[392px]'>
            <div className='h-[350px] ml-[-36px]'>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart width={150} height={40} data={histogram} maxBarSize={15}>
                        <CartesianGrid horizontal vertical={false} strokeDasharray={3} />
                        <XAxis dataKey='month' tickFormatter={getNameOfMonth} style={{ fontSize: 8 }} />
                        <YAxis style={{ fontSize: 10 }} />
                        <Tooltip formatter={currencyFormatter} labelFormatter={monthFormatter} cursor={false} />
                        <Legend />
                        <Bar
                            dataKey='revenue'
                            name={t('frontend.dashboard.cards.revenue.title')}
                            fill={primaryColor}
                            legendType='square'
                            className='font-tight'
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </CardInfo>
    )
}

export default TurnoverChartCardInfo
