import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Deal, DealPricingDocument, ShortDeal } from './DealService.types'
import { MakeAxiosPaginatedResponse, MakeAxiosResponse } from '../../Service.types'

export const getDealPage = async (
    axiosInstance: AxiosInstance,
    query: string,
    config: AxiosRequestConfig = {}
): Promise<MakeAxiosPaginatedResponse<ShortDeal>> => {
    const { data } = await axiosInstance.get<MakeAxiosPaginatedResponse<ShortDeal>>(`deal${query}`, config)
    return data
}

export const getDeal = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<Deal> => {
    const {
        data: { data: deal }
    } = await axiosInstance.get<MakeAxiosResponse<Deal>>(`deal/${id}`, config)
    return deal
}

export const getDealPricingDocument = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<DealPricingDocument> => {
    const {
        data: { data: dealPricingDocument }
    } = await axiosInstance.get<MakeAxiosResponse<DealPricingDocument>>(`deal/${id}/pricing-document`, config)
    return dealPricingDocument
}
