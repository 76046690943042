import { PlainTabProps } from '../plain-tabs/PlainTab'
import { Tab } from '@headlessui/react'
import { deepFind } from 'react-children-utilities'
import { getFunctionName } from '../../../utils/StringHelper'
import PlainTabs, { PlainTabList } from '../plain-tabs/PlainTabs'
import React, { Fragment, HTMLAttributes, PropsWithChildren } from 'react'

export type TabsProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> &
    Omit<PlainTabProps, 'onChange'> & {
        initialValue?: number
        onChange?: (index: number) => void
    }

const Tabs = ({ children, initialValue = 0, onChange, ...props }: PropsWithChildren<TabsProps>) => {
    const renderTabs = () => {
        return deepFind(children, child => {
            return React.isValidElement(child) && getFunctionName(child.type) === 'TabList'
        })
    }

    const renderTabPanels = () => {
        return deepFind(children, child => {
            return React.isValidElement(child) && getFunctionName(child.type) === 'TabPanels'
        })
    }

    return (
        <Tab.Group onChange={onChange} defaultIndex={initialValue} as={Fragment}>
            <Tab.List as={PlainTabs} {...props}>
                <PlainTabList>{renderTabs()}</PlainTabList>
            </Tab.List>
            <Tab.Panels>{renderTabPanels()}</Tab.Panels>
        </Tab.Group>
    )
}

Tabs.displayName = 'Tabs'

export const TabList = ({ children }: PropsWithChildren<{}>) => {
    return <>{children}</>
}

TabList.displayName = 'TabList'

export const TabPanels = ({ children }: PropsWithChildren<{}>) => {
    return <>{children}</>
}

TabPanels.displayName = 'TabPanels'

export default Tabs
