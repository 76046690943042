import { getStatistics } from '../../../services/api/statistics/StatisticsService'
import { useCurrentUser } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const useStatisticsGet = () => {
    const { isReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    return useQuery(
        ['statistics'],
        async ({ signal }) => {
            return await getStatistics(axiosInstance, { signal })
        },
        {
            enabled: isReady && isLogged
        }
    )
}

export default useStatisticsGet
