import { Row } from '@tanstack/react-table'
import { ShortDeal } from '../../../../services/api/deal/DealService.types'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import Link from 'next/link'
import React from 'react'

export type OrderTableActionCellProps = {
    row: Row<ShortDeal>
}

const OrderTableActionCell = ({ row }: OrderTableActionCellProps) => {
    const { t } = useTranslation()

    return (
        <div className='flex items-center gap-2.5'>
            <Button as={Link} variant='outlined' size='sm' href={`/app/order/${row.original.id}`}>
                {t('frontend.dashboard.order.table.action.detail')}
            </Button>
        </div>
    )
}

export default OrderTableActionCell
