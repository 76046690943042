import { Tab as HeadlessUiTab } from '@headlessui/react'
import PlainTab, { PlainTabProps } from '../plain-tabs/PlainTab'
import React, { Fragment, PropsWithChildren } from 'react'

export type TabProps = Omit<PlainTabProps, 'active'> & {}

const Tab = ({ children, ...props }: PropsWithChildren<TabProps>) => {
    return (
        <HeadlessUiTab as={Fragment}>
            {({ selected }) => {
                return (
                    <PlainTab {...props} active={selected}>
                        {children}
                    </PlainTab>
                )
            }}
        </HeadlessUiTab>
    )
}

Tab.displayName = 'Tab'

export default Tab
