import Column from '../../base/grid/Column'
import CurrentTurnoverCardInfo from './cards/CurrentTurnoverCardInfo'
import NearestDeliveryDateCardInfo from './cards/NearestDeliveryDateCardInfo'
import NumberOfDemandsCardInfo from './cards/NumberOfDemandsCardInfo'
import NumberOfOrderCardInfo from './cards/NumberOfOrderCardInfo'
import React from 'react'
import Row from '../../base/grid/Row'
import Skeleton from 'react-loading-skeleton'
import TurnoverChartCardInfo from './cards/TurnoverChartCardInfo'
import useStatisticsGet from '../../../hooks/api/statistics/useStatisticsGet'

const CardInfoPanel = () => {
    const { data, isLoading } = useStatisticsGet()

    if (isLoading || !data) {
        return (
            <Row>
                <Column cols={12}>
                    <div className='grid grid-cols-2 gap-5 grid-rows-1'>
                        <div className='col-span-1 row-span-1'>
                            <Skeleton height={250} className='!leading-[unset]' />
                        </div>
                        <div className='col-span-1 row-span-1 grid grid-cols-2 grid-rows-2 gap-5'>
                            <div className='grid-span-1 row-span-1'>
                                <Skeleton height='100%' className='!leading-[unset]' />
                            </div>
                            <div className='grid-span-1 row-span-1'>
                                <Skeleton height='100%' className='!leading-[unset]' />
                            </div>
                            <div className='grid-span-1 row-span-1'>
                                <Skeleton height='100%' className='!leading-[unset]' />
                            </div>
                            <div className='grid-span-1 row-span-1'>
                                <Skeleton height='100%' className='!leading-[unset]' />
                            </div>
                        </div>
                    </div>
                </Column>
            </Row>
        )
    }

    return (
        <Row>
            <Column cols={12}>
                <div className='grid grid-cols-2 gap-4 grid-rows-1'>
                    <div>
                        <TurnoverChartCardInfo histogram={data.histogram} />
                    </div>
                    <div className='col-span-1 row-span-1 grid grid-cols-2 grid-rows-2 gap-4'>
                        <div className='grid-span-1 row-span-1'>
                            <CurrentTurnoverCardInfo value={data.revenue} />
                        </div>
                        <div className='grid-span-1 row-span-1'>
                            <NumberOfOrderCardInfo value={data.deals} />
                        </div>
                        <div className='grid-span-1 row-span-1'>
                            <NumberOfDemandsCardInfo value={data.orders} />
                        </div>
                        <div className='grid-span-1 row-span-1'>
                            <NearestDeliveryDateCardInfo value={data.closestExpedition} />
                        </div>
                    </div>
                </div>
            </Column>
        </Row>
    )
}

export default CardInfoPanel
