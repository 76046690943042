import { RiShoppingBasket2Fill } from 'react-icons/ri'
import { useTranslation } from 'next-i18next'
import CardInfo from '../CardInfo'
import React from 'react'

export type NumberOfOrderCardInfoProps = {
    value: number
}

const NumberOfOrderCardInfo = ({ value }: NumberOfOrderCardInfoProps) => {
    const { t } = useTranslation()

    return (
        <CardInfo
            title={t('frontend.dashboard.cards.number_of_orders.title')}
            className='h-[187px]'
            icon={<RiShoppingBasket2Fill className='w-10 h-10' />}
        >
            <span className='font-normal'>{value}</span>
        </CardInfo>
    )
}

export default NumberOfOrderCardInfo
