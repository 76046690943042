import { RiArticleLine } from 'react-icons/ri'
import { useTranslation } from 'next-i18next'
import CardInfo from '../CardInfo'
import React from 'react'

export type CurrentTurnoverInfoCardProps = {
    value: number
}

const CurrentTurnoverInfoCard = ({ value }: CurrentTurnoverInfoCardProps) => {
    const { t } = useTranslation()

    return (
        <CardInfo
            title={t('frontend.dashboard.cards.number_of_demands.title')}
            className='h-[187px]'
            icon={<RiArticleLine className='w-10 h-10' />}
        >
            <span className='font-normal'>{value}</span>
        </CardInfo>
    )
}

export default CurrentTurnoverInfoCard
