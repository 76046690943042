import { RiMoneyEuroCircleFill } from 'react-icons/ri'
import { formatCurrency } from '../../../../utils/Helper'
import { useTranslation } from 'next-i18next'
import CardInfo from '../CardInfo'
import React from 'react'
import useUserCurrency from '../../../../hooks/useUserCurrency'
export type CurrentTurnoverCardInfoProps = {
    value: string
}

const CurrentTurnoverCardInfo = ({ value }: CurrentTurnoverCardInfoProps) => {
    const { t } = useTranslation()
    const currency = useUserCurrency()

    return (
        <CardInfo
            title={t('frontend.dashboard.cards.current_turnover.title')}
            icon={<RiMoneyEuroCircleFill className='w-10 h-10' />}
            className='h-[187px]'
        >
            <span className='font-normal'>{formatCurrency(value, currency)}</span>
        </CardInfo>
    )
}

export default CurrentTurnoverCardInfo
