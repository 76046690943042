import { DealStatus } from '../../../../services/api/deal/DealService.types'
import { useTranslation } from 'next-i18next'
import Badge from '../../../base/badge/Badge'
import React from 'react'

export type OrderStatusBadgeProps = {
    status: DealStatus
}

const OrderStatusBadge = ({ status }: OrderStatusBadgeProps) => {
    const { t } = useTranslation()

    switch (status) {
        case 'O':
            return <Badge color='orange'>{t('frontend.dashboard.order.table.statuses.sent_to_check')}</Badge>
        case 'Z':
            return <Badge color='blue'>{t('frontend.dashboard.order.table.statuses.sent_to_production')}</Badge>
        case 'N':
            return <Badge color='red'>{t('frontend.dashboard.order.table.statuses.not_allowed')}</Badge>
        case 'V':
            return <Badge color='grey'>{t('frontend.dashboard.order.table.statuses.production')}</Badge>
        case 'E':
            return <Badge color='green'>{t('frontend.dashboard.order.table.statuses.expedition')}</Badge>
        default:
            return <Badge color='primary'>{status}</Badge>
    }
}

export default OrderStatusBadge
