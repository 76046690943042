import PostponeDeliveryListItem from './PostponeDeliveryListItem'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import usePostponeDeliveryList from '../../../hooks/api/postpone-delivery/usePostponeDeliveryList'

const PostponeDeliveryList = () => {
    const { data, isLoading } = usePostponeDeliveryList()

    if (isLoading) {
        return (
            <div className='flex flex-col gap-2.5'>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </div>
        )
    }

    return (
        <ul className='flex flex-col gap-2.5'>
            {data.map((postponeDelivery, index) => {
                return <PostponeDeliveryListItem notice={postponeDelivery.notice} key={index} />
            })}
        </ul>
    )
}

export default PostponeDeliveryList
