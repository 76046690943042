import { DealStatus, ShortDeal } from '../../../../services/api/deal/DealService.types'
import { Filter } from '../../../../utils/ApiHelper'
import { TableFilterProps } from '../../../base/table/filter/TableFilter'
import { useTranslation } from 'next-i18next'
import React, { useMemo } from 'react'
import SelectField from '../../../base/select-field/SelectField'

export type OrderTableStatusFilterProps = TableFilterProps<ShortDeal>

type OrderStatusOption = {
    value: DealStatus
    label: string
}

const OrderTableStatusFilter = ({ column, table }: OrderTableStatusFilterProps) => {
    const { t } = useTranslation('common')
    const filter = column.getFilterValue()
    const filterValue = filter as Filter | undefined

    const statuses = useMemo<OrderStatusOption[]>(() => {
        return [
            {
                value: 'O',
                label: t('frontend.dashboard.order.table.statuses.sent_to_check')
            },
            {
                value: 'Z',
                label: t('frontend.dashboard.order.table.statuses.sent_to_production')
            },
            {
                value: 'N',
                label: t('frontend.dashboard.order.table.statuses.not_allowed')
            },
            {
                value: 'V',
                label: t('frontend.dashboard.order.table.statuses.production')
            },
            {
                value: 'E',
                label: t('frontend.dashboard.order.table.statuses.expedition')
            }
        ]
    }, [t])

    const value = statuses?.find(option => {
        return option.value === filterValue?.value
    })

    const getOptionValue = (option: OrderStatusOption) => {
        return option.value
    }

    const getOptionLabel = (option: OrderStatusOption) => {
        return option.label
    }

    const onChange = (option: OrderStatusOption) => {
        table.setPageIndex(0)
        if (!option) {
            column.setFilterValue(null)
            return
        }
        column.setFilterValue({
            type: 'string',
            value: option.value
        })
    }

    return (
        <SelectField
            isClearable
            srOnlyLabel
            isSearchable
            disablePortal
            size='sm'
            options={statuses}
            label={column.columnDef.id}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onChange}
        />
    )
}

export default OrderTableStatusFilter
