import { getPostponeDeliveryList } from '../../../services/api/pospone-delivery/PostponeDeliveryService'
import { useCurrentUser } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const usePostponeDeliveryList = () => {
    const { isReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    return useQuery(
        ['postponeDeliveryList'],
        async ({ signal }) => {
            return getPostponeDeliveryList(axiosInstance, { signal })
        },
        {
            enabled: isReady && isLogged
        }
    )
}

export default usePostponeDeliveryList
