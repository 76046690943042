import { PriceOfferTableProvider } from '../price-offers/table/PriceOfferTable.context'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Card from '../../base/card/Card'
import CardText from '../../base/card/CardText'
import Column from '../../base/grid/Column'
import DemandTable from '../demand/table/DemandTable'
import NextLink from 'next/link'
import OrderTable from '../order/table/OrderTable'
import PriceOfferTable from '../price-offers/table/PriceOfferTable'
import React from 'react'
import Row from '../../base/grid/Row'
import Tab from '../../base/tabs/Tab'
import TabPanel from '../../base/tabs/TabPanel'
import Tabs, { TabList, TabPanels } from '../../base/tabs/Tabs'

// const OFFSET_HEIGHT = 120
const TABLE_CONTENT_HEIGHT = 300

const TabInfoPanel = () => {
    const { t } = useTranslation()
    const router = useRouter()

    const redirectToDemand = (id: string | number) => {
        return router.push(`/app/demand/${id}`)
    }
    const redirectToOrder = (id: string | number) => {
        return router.push(`/app/order/${id}`)
    }
    const redirectToPriceOffer = (id: string | number) => {
        return router.push(`/app/price-offers/create/${id}`)
    }

    const sharedTableProps = {
        pagination: false,
        filtering: false,
        sorting: false,
        rounded: false
    }

    return (
        <Row>
            <Column cols={12}>
                <Card shadow className='p-3 !rounded-lg'>
                    <CardText removePaddingX removePaddingY style={{ minHeight: 150 }}>
                        <Tabs>
                            <TabList>
                                <Tab grow>{t('frontend.dashboard.demands.title')}</Tab>
                                <Tab grow>{t('frontend.dashboard.orders.title')}</Tab>
                                <Tab grow>{t('frontend.dashboard.price_offers.title')}</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <div className='overflow-hidden rounded-lg'>
                                        <DemandTable
                                            hasFiltersRow={false}
                                            dashboard
                                            pageSize={5}
                                            disableActionColumn
                                            hasHeader={false}
                                            showShowAllAction
                                            canDelete={false}
                                            selecting={false}
                                            height={TABLE_CONTENT_HEIGHT}
                                            onClickRow={row => {
                                                return redirectToDemand(row.original.id)
                                            }}
                                            customEmpty={
                                                <div className=''>
                                                    Žádné poptávky nejsou vytvořeny,&nbsp;
                                                    <NextLink
                                                        href='/app/demand/create'
                                                        className='text-primary-300 underline'
                                                    >
                                                        vytvořte první zde
                                                    </NextLink>
                                                </div>
                                            }
                                            {...sharedTableProps}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className='overflow-hidden rounded-lg'>
                                        <OrderTable
                                            hasFiltersRow={false}
                                            dashboard
                                            pageSize={5}
                                            hasHeader={false}
                                            showShowAllAction
                                            rounded={false}
                                            height={TABLE_CONTENT_HEIGHT}
                                            onClickRow={row => {
                                                return redirectToOrder(row.original.id)
                                            }}
                                            customEmpty={<>Žádné objednávky nejsou vytvořeny.</>}
                                            {...sharedTableProps}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className='overflow-hidden'>
                                        <PriceOfferTableProvider>
                                            <PriceOfferTable
                                                showShowAllAction
                                                pageSize={5}
                                                hasFiltersRow={false}
                                                dashboard
                                                hasHeader={false}
                                                initialSorts={[{ id: 'updatedAt', desc: true }]}
                                                onClickRow={row => {
                                                    return redirectToPriceOffer(row.original.id)
                                                }}
                                                customEmpty={
                                                    <>
                                                        Žádné cenové nabídky nejsou vytvořeny,&nbsp;
                                                        <NextLink
                                                            href={{
                                                                pathname: '/app/price-offers',
                                                                query: {
                                                                    openDialog: true
                                                                }
                                                            }}
                                                            className='text-primary-300 underline'
                                                        >
                                                            vytvořte první zde
                                                        </NextLink>
                                                    </>
                                                }
                                                {...sharedTableProps}
                                            />
                                        </PriceOfferTableProvider>
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </CardText>
                </Card>
            </Column>
        </Row>
    )
}

export default TabInfoPanel
