import { AxiosInstance, AxiosRequestConfig } from 'axios/index'
import { MakeAxiosResponse } from '../../Service.types'
import { PostponeDelivery } from './PostponeDeliveryService.types'

export const getPostponeDeliveryList = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<PostponeDelivery[]> => {
    const {
        data: { data: postponeDeliveries }
    } = await axiosInstance.get<MakeAxiosResponse<PostponeDelivery[]>>('postpone-delivery', config)
    return postponeDeliveries
}
