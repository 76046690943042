import { PiCalendarBlank } from 'react-icons/pi'
import { useTranslation } from 'next-i18next'
import CardInfo from '../CardInfo'
import React from 'react'
import TruncateTooltip from '../../../base/truncate-tooltip/TruncateTooltip'

export type CurrentTurnoverInfoCardProps = {
    value: string
}

const CurrentTurnoverInfoCard = ({ value }: CurrentTurnoverInfoCardProps) => {
    const { t } = useTranslation()

    return (
        <CardInfo
            title={t('frontend.dashboard.cards.nearest_delivery_date.title')}
            className='h-[187px] px-4'
            icon={<PiCalendarBlank className='w-10 h-10' />}
        >
            <span>
                <TruncateTooltip text={value} />
            </span>
        </CardInfo>
    )
}

export default CurrentTurnoverInfoCard
