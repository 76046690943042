import { Tab } from '@headlessui/react'
import React, { PropsWithChildren } from 'react'

export type TabPanelProps = {
    unmount?: boolean
}

const TabPanel = ({ children, unmount }: PropsWithChildren<TabPanelProps>) => {
    return <Tab.Panel unmount={unmount}>{children}</Tab.Panel>
}

TabPanel.displayName = 'TabPanel'

export default TabPanel
