import Alert from '../../base/alert/Alert'
import React from 'react'

export type PostponeDeliveryListItemProps = {
    notice: string
}

const PostponeDeliveryListItem = ({ notice }: PostponeDeliveryListItemProps) => {
    return (
        <li>
            <Alert color='warning' className='w-full'>
                {notice}
            </Alert>
        </li>
    )
}

export default PostponeDeliveryListItem
