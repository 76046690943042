import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'

export type SimpleListProps = HTMLAttributes<HTMLUListElement> & {}

const SimpleList = (
    { children, className, ...props }: PropsWithChildren<SimpleListProps>,
    ref: ForwardedRef<HTMLUListElement>
) => {
    return (
        <ul {...props} className={buildClassesWithDefault('simple-list', className)} ref={ref}>
            {children}
        </ul>
    )
}

export default forwardRef(SimpleList)
