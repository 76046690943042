import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'

export type SimpleListProps = HTMLAttributes<HTMLLIElement> & {}

const SimpleListItem = (
    { children, className, ...props }: PropsWithChildren<SimpleListProps>,
    ref: ForwardedRef<HTMLLIElement>
) => {
    return (
        <li {...props} className={buildClassesWithDefault('simple-list-item', className)} ref={ref}>
            {children}
        </li>
    )
}

export default forwardRef(SimpleListItem)
