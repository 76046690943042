import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { MakeAxiosResponse } from '../../Service.types'
import { Statistics } from './StatisticsService.types'

export const getStatistics = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<Statistics> => {
    const {
        data: { data: statistics }
    } = await axiosInstance.get<MakeAxiosResponse<Statistics>>('statistics', config)
    return statistics
}
