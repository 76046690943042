import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import Card, { CardProps } from '../../base/card/Card'
import CardText from '../../base/card/CardText'
import CardTitle from '../../base/card/CardTitle'
import React, { PropsWithChildren, ReactNode } from 'react'

export type InfoCardProps = CardProps & {
    title: string
    icon?: ReactNode
}

const CardInfo = ({ children, title, icon, className, ...props }: PropsWithChildren<InfoCardProps>) => {
    return (
        <Card
            {...props}
            className={buildClassesWithDefault('flex flex-col w-full justify-center !rounded-lg', className)}
            shadow
        >
            <CardTitle>
                <h3 className='text-sm font-light flex flex-col justify-center items-center w-full text-center text-secondary'>
                    <span className='block mb-1.5 text-primary'>{icon}</span>
                    {title}
                </h3>
            </CardTitle>
            <CardText className='text-center text-body-regular font-normal w-full text-grey-800 pb-1.5'>
                {children}
            </CardText>
        </Card>
    )
}

export default CardInfo
